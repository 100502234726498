import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar,
  Typography,
  Container,
  Divider,
  Box,
  Dialog,
  IconButton,
  Grid,
  Tab,
  Tabs,
  DialogContent,
  FormControlLabel,
  Switch,
  BottomNavigation,
  BottomNavigationAction,
  Button,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import Slider from '@mui/material/Slider';
import GamepadIcon from '@mui/icons-material/Gamepad';
import LightModeIcon from '@mui/icons-material/LightMode';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import { dfu } from 'webdfu';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const styles = {
  root: {
    backgroundColor: '#f4f7fa',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  closeButton: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
  settingsButton: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
};


const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });

  const setValue = (value) => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.log(error);
    }
  };

  return [storedValue, setValue];
};

const TopBar = ({ connected, onConnect, onDisconnect, sliderValue1, sliderValue2, sliderValue3, sliderValue4, sliderValue5, sliderValue6, sliderValue7, sliderValue8, sliderValue10, onSendData }) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: 58,
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 2,
      }}
    >
      <Typography variant="h6" sx={{ flexGrow: 1 }}>
        PCI Lab Alpha
      </Typography>
      {connected ? (
        <Typography variant="subtitle1" sx={{ marginRight: 2 }}>
          Connected
        </Typography>
      ) : (
        <Button
          variant="contained"
          color="secondary"
          sx={{ marginRight: 2 }}
          onClick={onConnect}
        >
          Connect
        </Button>
      )}
      <Button
        variant="contained"
        sx={{ marginRight: 4 }}
        disabled={!connected}
        onClick={() =>
          onSendData([
            6,
            sliderValue1,
            sliderValue2,
            sliderValue3,
            sliderValue6,
            sliderValue5,
            29,
            27,
            sliderValue4,
            sliderValue10,
            sliderValue10,
            sliderValue10,
            6,
          ])
        }
      >
        Save
      </Button>
    </Box>
  );
};

const SettingsDialog = ({ open, onClose, showOnStartup, setShowOnStartup }) => {
  const [tabValue, setTabValue] = useState(0);

  
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const connectWebDFUDevice = async () => {
    const vendorId = 0x0727; // Replace with the vendor ID of your WebDFU device
    const productId = 0x0727 // Replace with the product ID of your WebDFU device

    try {
      const devices = await navigator.usb.requestDevice({ filters: [{ vendorId, productId }] });
      const device = new dfu.Device(devices);
      await device.open();
      await device.selectConfiguration(1);
      await device.claimInterface(0);

      console.log('Connected to WebDFU device:', device);
    } catch (error) {
      console.error('Error connecting to WebDFU device:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <DialogContent sx={{ display: 'flex', backgroundColor: styles.root.backgroundColor, minHeight: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '20%',
            paddingRight: 2,
            borderRight: '1px solid rgba(0, 0, 0, 0.12)',
            minHeight: '100%',
          }}
        >
          <Typography variant="h6" fontWeight="bold" sx={{ marginBottom: 1 }}>
            Settings
          </Typography>
          <Tabs orientation="vertical" value={tabValue} onChange={handleTabChange} sx={{ flexGrow: 1 }}>
            <Tab label="SettingsTab1" />
            <Tab label="SettingsTab2" />
            <Tab label="SettingsTab3" />
          </Tabs>
        </Box>
        <Box sx={{ width: '80%', flexGrow: 1 }}>
          {tabValue === 0 && (
            <Typography>
              <FormControlLabel
                control={<Switch checked={showOnStartup} onChange={(event) => setShowOnStartup(event.target.checked)} />}
                label="Show this window on startup"
              />
            </Typography>
          )}
          {tabValue === 1 && <Typography>Content for SettingsTab2
           
     </Typography>}
       {tabValue === 2 && (
            <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              
            </Box>
          )}
        </Box>
        <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close" sx={{ position: 'absolute', top: 16, right: 16 }}>
          <CloseIcon />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

const StartupDialog = ({ open, onClose, showOnStartup, setShowOnStartup }) => {
  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <DialogContent sx={{ overflow: 'hidden' }}>
        <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close" sx={{ position: 'absolute', top: 8, right: 8 }}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h6">Welcome to PCI Lab</Typography>
        <Typography>Программа и прошивка в экспериментальной стадии, так что возможны ошибки и недочеты, ложные срабатывания и все это</Typography>
        <FormControlLabel
          control={<Switch checked={showOnStartup} onChange={(event) => setShowOnStartup(event.target.checked)} />}
          label="Show this window on startup"
        />
      </DialogContent>
    </Dialog>
  );
};

const Tab1Content = ({ sliderValue4, setSliderValue4, sliderValue5, setSliderValue5, sliderValue6, setSliderValue6, sliderValue10, setSliderValue10 }) => {
  const [showSensitivity, setShowSensitivity] = useState(true);

  const handleSlider4Change = (event, newValue) => {
    setSliderValue4(newValue);
    localStorage.setItem('sliderValue4', newValue);
  };

  const handleSlider5Change = (event, newValue) => {
    setSliderValue5(newValue);
    localStorage.setItem('sliderValue5', newValue);
  };

  const handleSlider6Change = (event, newValue) => {
    setSliderValue6(newValue);
    localStorage.setItem('sliderValue6', newValue);
  };

  const handleSlider10Change = (event, newValue) => {
    setSliderValue10(newValue);
    localStorage.setItem('sliderValue10', newValue);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 58px - 56px)', flexGrow: 1 }}>
      <Box
        sx={{
          backgroundColor: '#f4f7fa',
          height: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          flexDirection: 'column',
          padding: 2,
        }}
      >
        {/* You can add content here */}
      </Box>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          height: '70%',
          width: '100%',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#f4f7fa',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '50%',
            padding: 2,
          }}
        >
          <Box sx={{ alignSelf: 'flex-start', marginLeft: 1 }}>
            <Typography variant="h6" sx={{ marginBottom: 2 }}>Actuation point</Typography>
            <Typography variant="h8">The point to activate keypress</Typography>
          </Box>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
            
            <Slider
              value={sliderValue4}
              onChange={handleSlider4Change}
              min={1}
              max={40}
              aria-labelledby="slider-4"
              sx={{ width: 200 }}
            />
            <Typography sx={{ marginLeft: 2 }}>{(sliderValue4 / 10).toFixed(2)} mm</Typography>
          </Box>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box
          sx={{
            backgroundColor: '#f4f7fa',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '50%',
            padding: 2,
          }}
        >
          {/* Move the switch to the top of the second part */}
          <Box sx={{ alignSelf: 'flex-start', marginLeft: 1, display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6" sx={{}}>Rapid Trigger</Typography>
            
            
             </Box>
      {showSensitivity && (
        <>
       <Slider
                value={sliderValue10}
                onChange={handleSlider10Change}
                min={0}
                max={2}
                aria-labelledby="slider-10"
                sx={{ width: 100 }}
              />
              <Typography sx={{ marginLeft: 2 }}>{(sliderValue10)}  </Typography>
              <Typography variant="h8">0 - OFF, 1 - RT, 2 - CRT. For now recommended CRT due to its stability.</Typography>
          <Box sx={{ alignSelf: 'flex-start', marginLeft: 1 }}>
            <Typography variant="h6" sx={{ marginBottom: 1 }}>Sensitivity</Typography>
            <Typography variant="h8">The sensitivity determines how much you need to release the key to deactivate it and then press to activate it again</Typography>
          </Box>
          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography sx={{ marginLeft: 1 }}>Up</Typography>
              <Slider
                value={sliderValue5}
                onChange={handleSlider5Change}
                min={5}
                max={255}
                aria-labelledby="slider-5"
                sx={{ width: 200 }}
              />
              <Typography sx={{ marginLeft: 2 }}>{(sliderValue5 / 100).toFixed(2)} mm</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography sx={{ marginLeft: 1 }}>Down</Typography>
              <Slider
                value={sliderValue6}
                onChange={handleSlider6Change}
                min={5}
                max={255}
                aria-labelledby="slider-6"
                sx={{ width: 200 }}
              />
              <Typography sx={{ marginLeft: 2 }}>{(sliderValue6 / 100).toFixed(2)} mm</Typography>
            </Box>
          </Box>
        </>
      )}
    </Box>
  </Box>
</Box>
);
};


const Tab2Content = ({ sliderValue1, setSliderValue1, sliderValue2, setSliderValue2, sliderValue3, setSliderValue3, onSendData }) => {

  const handleSlider1Change = (event, newValue) => {
    setSliderValue1(newValue);
    localStorage.setItem('sliderValue1', newValue);
  };

  const handleSlider2Change = (event, newValue) => {
    setSliderValue2(newValue);
    localStorage.setItem('sliderValue2', newValue);
  };

  const handleSlider3Change = (event, newValue) => {
    setSliderValue3(newValue);
    localStorage.setItem('sliderValue3', newValue);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 58px - 56px)', flexGrow: 1 }}>
      <Box
        sx={{
          backgroundColor: '#f4f7fa',
          height: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          flexDirection: 'column',
          padding: 2,
        }}
      >
      </Box>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          height: '50%',
          width: '100%',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#f4f7fa',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: '50%',
            padding: 2,
          }}
        >
          <Typography sx={{ marginLeft: 1 }}>Color</Typography>
          <Slider
              label="Пошел нахуй"
              value={sliderValue1}
              onChange={handleSlider1Change}
              min={1}
              max={255}
              aria-labelledby="slider-1"
              sx={{ width: 200 }}
            />
            <Typography sx={{ marginLeft: 1 }}>Saturation</Typography>
            <Slider
              value={sliderValue2}
              onChange={handleSlider2Change}
              min={1}
              max={255}
              aria-labelledby="slider-2"
              sx={{ width: 200 }}
            />
            <Typography sx={{ marginLeft: 1 }}>Brightness</Typography>
            <Slider
              value={sliderValue3}
              onChange={handleSlider3Change}
              min={1}
              max={255}
              aria-labelledby="slider-3"
              sx={{ width: 200 }}
            />
         
        </Box>
        
        <Button variant="contained" onClick={() => onSendData([13, sliderValue1, sliderValue2, sliderValue3])}>
      Просто цвет
      </Button>
      <Button variant="contained" onClick={() => onSendData([14, sliderValue1, sliderValue2, sliderValue3])}>
      Дышащий
      </Button>
      <Button variant="contained" onClick={() => onSendData([15, sliderValue1, sliderValue2, sliderValue3])}>
      Лгбт1)
      </Button>
      <Button variant="contained" onClick={() => onSendData([16, sliderValue1, sliderValue2, sliderValue3])}>
      Лгбт2)
      </Button>
      <Button variant="contained" onClick={() => onSendData([17, sliderValue1, sliderValue2, sliderValue3])}>
      Хз1
      </Button>
      <Button variant="contained" onClick={() => onSendData([22, sliderValue1, sliderValue2, sliderValue3])}>
      Хз11
      </Button>
      <Button variant="contained" onClick={() => onSendData([23, sliderValue1, sliderValue2, sliderValue3])}>
      Хз12
      </Button>
      <Button variant="contained" onClick={() => onSendData([18, sliderValue1, sliderValue2, sliderValue3])}>
      Хз2
      </Button>
      <Button variant="contained" onClick={() => onSendData([19, sliderValue1, sliderValue2, sliderValue3])}>
      Хз3
      </Button>
      <Button variant="contained" onClick={() => onSendData([20, sliderValue1, sliderValue2, sliderValue3])}>
      Хз4
      </Button>
      <Button variant="contained" onClick={() => onSendData([21, sliderValue1, sliderValue2, sliderValue3])}>
      Хз5
      </Button>
      <Button variant="contained" onClick={() => onSendData([24, sliderValue1, sliderValue2, sliderValue3])}>
      Вырубить
      </Button>
      <Button variant="contained" onClick={() => onSendData([25, sliderValue1, sliderValue2, sliderValue3])}>
      тестовая залупка
      </Button>
      </Box>
    </Box>
  );
};

const Tab3Content = ({ onConnect, onDisconnect, onSendData, sliderValue5, sliderValue6 }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100vh - 58px - 56px)',
        flexGrow: 1,
      }}
    >
      <Button
        variant="contained"
        color="primary"
        sx={{ marginBottom: 2 }}
        onClick={onConnect}
      >
        Connect
      </Button>
      <Button
        variant="contained"
        color="secondary"
        sx={{ marginBottom: 2 }}
        onClick={onDisconnect}
      >
        Disconnect
      </Button>
      
      <Button variant="contained" onClick={() => onSendData([10])}>
      RESET EEPROM
      </Button>
      <Button variant="contained" onClick={() => onSendData([11])}>
      CALIBRATION
      </Button>
      <Button variant="contained" onClick={() => onSendData([8])}>
      DFU
      </Button>
      <Button variant="contained" onClick={() => onSendData([12])}>
      Print values
      </Button>
    </Box>
  );
};
const App = () => {
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [previousTabValue, setPreviousTabValue] = useState(null);
  const [showOnStartup, setShowOnStartup] = useLocalStorage('showOnStartup', true);
  const [startupOpen, setStartupOpen] = useState(showOnStartup);
  const [startupOpenState, setStartupOpenState] = useState(showOnStartup);
  const [device, setDevice] = useState(null);
  const connected = Boolean(device);
  const [selectedButton, setSelectedButton] = useState(null);
  const [sliderValue1, setSliderValue1] = useState(parseFloat(localStorage.getItem('sliderValue1')) || 255);
  const [sliderValue2, setSliderValue2] = useState(parseFloat(localStorage.getItem('sliderValue2')) || 255);
  const [sliderValue3, setSliderValue3] = useState(parseFloat(localStorage.getItem('sliderValue3')) || 255);
  const [sliderValue4, setSliderValue4] = useState(parseFloat(localStorage.getItem('sliderValue4')) || 3);
  const [sliderValue5, setSliderValue5] = useState(parseFloat(localStorage.getItem('sliderValue5')) || 30);
  const [sliderValue6, setSliderValue6] = useState(parseFloat(localStorage.getItem('sliderValue6')) || 30);
  const [sliderValue7, setSliderValue7] = useState(parseFloat(localStorage.getItem('sliderValue7')) || 30);
  const [sliderValue8, setSliderValue8] = useState(parseFloat(localStorage.getItem('sliderValue8')) || 30);
  const [sliderValue10, setSliderValue10] = useState(parseFloat(localStorage.getItem('sliderValue10')) || 2);

  useEffect(() => {
    if (showOnStartup) {
      setStartupOpen(true);
    } else {
      setStartupOpen(false);
    }
  }, [showOnStartup]);

  const handleSettingsClick = () => {
    setPreviousTabValue(tabValue);
    setTabValue(-1);
    setSettingsOpen(true);
  };

  const handleSettingsClose = () => {
    setSettingsOpen(false);
    if (previousTabValue !== null) {
      setTabValue(previousTabValue);
    }
  };

  const handleStartupClose = () => {
  setStartupOpenState(false);
};

const padMessage = (payload) => {
  const padded = new Uint8Array(32);
  padded.set(payload);
  return padded;
};

const toBytes = (data) => {
  return new Uint8Array(data);
};

const connect = async () => {
  const filters = [
    {
      vendorId: 0x0727,
      productId: 0x0727,
      usagePage: 0xff60,
      usage: 0x61,
    },
  ];

  try {
    const devices = await navigator.hid.requestDevice({ filters });
    const selectedDevice = devices[0];
    if (!selectedDevice) return;

    await selectedDevice.open();
    console.log('Device opened:', selectedDevice);

    setDevice(selectedDevice);
  } catch (error) {
    console.error('Error:', error);
  }
};

const disconnect = async () => {
  if (device) {
    await device.close();
    console.log('Device closed');
    setDevice(null);
  }
};


const sendData = async (dataToSend) => {
  console.log("Sending data with values:", dataToSend);
  
  if (device) {
    const data = padMessage(toBytes(dataToSend));
    try {
      await device.sendReport(0, data);
      console.log("Data sent:", data);
    } catch (error) {
      console.error("Error sending data:", error);
    }
  }
};

  const handleTabChange = (event, newValue) => {
    if (newValue !== -1) {
      setTabValue(newValue);
    }
  };
  return (
    <Box sx={styles.root}>
      <TopBar connected={connected} onSendData={sendData} onConnect={connect} onDisconnect={disconnect} sliderValue1={sliderValue1} sliderValue2={sliderValue2} sliderValue3={sliderValue3} sliderValue4={sliderValue4} sliderValue5={sliderValue5} sliderValue6={sliderValue6} sliderValue7={sliderValue7}
  sliderValue8={sliderValue8} sliderValue10={sliderValue10}/>
      <Box sx={{ marginTop: 2, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
      {tabValue === 0 && (
  <Tab1Content
    sliderValue4={sliderValue4}
    setSliderValue4={setSliderValue4}
    sliderValue5={sliderValue5}
    setSliderValue5={setSliderValue5}
    sliderValue6={sliderValue6}
    setSliderValue6={setSliderValue6}
    sliderValue10={sliderValue10}
    setSliderValue10={setSliderValue10}
  />
)}
        {tabValue === 1 && <Tab2Content 
         sliderValue1={sliderValue1}
         setSliderValue1={setSliderValue1}
         sliderValue2={sliderValue2}
         setSliderValue2={setSliderValue2}
         sliderValue3={sliderValue3}
         setSliderValue3={setSliderValue3}
         sliderValue7={sliderValue7}
    setSliderValue7={setSliderValue7}
    sliderValue8={sliderValue8}
    setSliderValue8={setSliderValue8}
    sliderValue10={sliderValue10}
    setSliderValue10={setSliderValue10} onSendData={sendData} />}
        {tabValue === 2 && <Tab3Content onConnect={connect} onDisconnect={disconnect} onSendData={sendData}/>}
      </Box>
      <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
        <BottomNavigation showLabels value={tabValue} onChange={handleTabChange}>
          <BottomNavigationAction icon={<KeyboardIcon />}/>
          <BottomNavigationAction icon={<LightModeIcon />}/>
          <BottomNavigationAction icon={<QuestionMarkIcon />}/>
          <BottomNavigationAction icon={<SettingsIcon />} onClick={handleSettingsClick} />
        </BottomNavigation>
      </Box>
      <SettingsDialog open={settingsOpen} onClose={handleSettingsClose} showOnStartup={showOnStartup} setShowOnStartup={setShowOnStartup} />
      <StartupDialog open={startupOpenState} onClose={handleStartupClose} showOnStartup={showOnStartup} setShowOnStartup={setShowOnStartup} />
    </Box>
  );
};






export default App;